<template>
  <div>
    <div class="card m-3">
      <div class="card-body">
        <h5 style="    margin-bottom: 20px;">Change Password</h5>
        <Form
          @submit="onSubmit"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label>Current Password</label>
                <div style="position:relative">
                  <Field
                  name="currentpassword"
                  :type="passwordCls"
                  class="form-control"
                  :class="{ 'is-invalid': errors.currentpassword }"
                />
                <div class="invalid-feedback">{{ errors.currentpassword }}</div>
                <button
                  type="button"
                  @click="changeType()"
                  id="btnToggle"
                  class="toggle passeye"
                >
                  <i
                    id="eyeIcon"
                    v-if="passwordCls == 'text'"
                    class="fas fa-eye-slash"
                  ></i>
                  <i
                    id="eyeIcon"
                    v-if="passwordCls == 'password'"
                    class="fa fa-eye"
                  ></i>
                </button>
                </div>
                
                
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label>New Password</label>
                <div style="position:relative;">
                  <Field
                  name="newpassword"
                  :type="passwordClsC"
                  class="form-control"
                  :class="{ 'is-invalid': errors.newpassword }"
                />
                <div class="invalid-feedback">{{ errors.newpassword }}</div>
                <button
                  type="button"
                  id="btnToggle"
                  @click="changeTypeC()"
                  class="toggle passeye"
                >
                  <i
                    id="eyeIcon"
                    v-if="passwordClsC == 'text'"
                    class="fas fa-eye-slash"
                  ></i>
                  <i
                    id="eyeIcon"
                    v-if="passwordClsC == 'password'"
                    class="fa fa-eye"
                  ></i>
                </button>
                </div>
                
                
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label>Confirm Password</label>
                <div style="position:relative">
                  <Field
                  name="confirmPassword"
                  :type="passwordClsN"
                  class="form-control"
                  :class="{ 'is-invalid': errors.confirmPassword }"
                />
                <div class="invalid-feedback">{{ errors.confirmPassword }}</div>
                <button
                  type="button"
                  id="btnToggle"
                  @click="changeTypeN()"
                  class="toggle passeye"
                >
                  <i
                    id="eyeIcon"
                    v-if="passwordClsN == 'text'"
                    class="fas fa-eye-slash"
                  ></i>
                  <i
                    id="eyeIcon"
                    v-if="passwordClsN == 'password'"
                    class="fa fa-eye"
                  ></i>
                </button>
                </div>
              
                
              </div>
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary mr-1">Submit</button>
          </div>
        </Form>
        <div
          v-if="message"
          class="alert"
          :class="successful ? 'alert-success' : 'alert-danger'"
        >
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userService from "@/services/user.service";
import { Form, Field } from "vee-validate";
import * as yup from "yup";
export default {
  name: "CustomerResetPassword",
  components: {
    Form,
    Field,
  },
  data() {
    const schema = yup.object().shape({
      currentpassword: yup
        .string()       
        .required("Current password is required"),
      newpassword: yup
        .string()
        .min(6, "Password must be at least 6 characters")
        .required("New password is required"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("newpassword"), null], "Passwords must match")
        .required("Confirm password is required"),
    });
    return {
      schema,
      message: "",
      successful: false,
      userinfo: "",
      custId: "",
      passwordCls: "password",
      passwordClsC: "password",
      passwordClsN: "password",
    };
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    if (this.userinfo) {
      if (typeof this.userinfo.sellerId != "undefined") {
        this.custId = this.userinfo.sellerId;
      } else {
        console.log("vendor");
      }
    }
  },
  methods: {
    changeType() {
      this.passwordCls = this.passwordCls == "password" ? "text" : "password";
    },
    changeTypeC() {
      this.passwordClsC = this.passwordClsC == "password" ? "text" : "password";
    },
    changeTypeN() {
      this.passwordClsN = this.passwordClsN == "password" ? "text" : "password";
    },
    onSubmit(values) {
      this.message = "";
      userService.changePasswordVendor(this.custId, values).then(
        (data) => {
          if (data.data.Message == "Success") {
            this.message = "Password Changed Successfully";
        setTimeout(() => {
            location.reload();
        }, 3000);
          } else {
            this.message = data.data.Message;
          }
          this.successful = true;
        },
        (error) => {
          this.message =
            (error.response && error.response.data.Message) ||
            error.message ||
            error.toString();
          this.successful = false;
        }
      );
    },
    clearFields() {
      this.successful = false;
      this.message = "";
    },
  },
};
</script>
